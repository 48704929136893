<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd' : 'zhadminadd'" style="min-width: 910px;">
    <div style="text-align: right; z-index: 1000;">
      <el-button size="small" style="text-align: right;" type="primary" @click="isqpisboot = !isqpisboot">{{ isqpisboot ?
        $t('All.取消全屏') : $t('All.全屏') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="(form.State == 3 || form.State == 1) && ($store.state.Login.User == form.User || $store.state.Login.Jurisdiction == 0)"
        type="danger" @click="Deluseroqc()">{{ $t('system.zuofei') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="form.State == 4 && ($store.state.Login.Jurisdiction == 4 || $store.state.Login.Jurisdiction == 0)"
        type="warning" @click="qianhe = true">{{ $t('All.签核') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="form.State == 5 && ($store.state.Login.Jurisdiction == 3 || $store.state.Login.Jurisdiction == 0)"
        type="warning" @click="qianhe = true">{{ $t('All.签核') }}</el-button>
        <el-button size="small" style="text-align: right;"
        v-if="form.State == 2 &&  $store.state.Login.Jurisdiction == 0"
        type="warning" @click="qianhe = true">{{ $t('All.签核') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="(form.State == 3 || form.State == 1) && $store.state.Login.User == form.User" type="primary"
        @click="Upduseroqc(form, 4)">{{ $t('All.提交') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="(form.State == 3 || form.State == 1) && $store.state.Login.User == form.User" type="primary"
        @click="Upduseroqc(form, 3)">{{ $t('All.暂存') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="$store.state.Login.User == form.User && (form.State == 1 || form.State == 3) && Isbool" type="success"
        @click="Isbool = false">{{ this.$t('system.unlock') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="$store.state.Login.User == form.User && (form.State == 1 || form.State == 3) && !Isbool" type="primary"
        @click="Isbool = true">{{ this.$t('system.lock') }}</el-button>
    </div>
    <h2 style="text-align: center;">{{$t('All.年益实业股份有限公司')}}</h2>
    <h3 style="text-align: center;">{{$t('All.OQC出货报告')}}</h3>
    <h4 style="text-align: center;">{{$t('All.系统单号')}}：{{ form.Numbers }}</h4>
    <h4 :style="form.Determine == 'Fail' ? 'color: red;' : ''" style="text-align: center;">{{ form.Determine }}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%;" align="center" cellpadding="10">
        <tr>
          <td style="width: 230px;">
            <p style="margin: 0;line-height: 1.2;width: 230px;">{{$t('All.出货日期')}}<br>Shipping date</p>
          </td>
          <td style="width: 200px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.客户')}}<br>Customer</p>
          </td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.参考文件')}}<br>Reference documents</p>
          </td>
          <td style="width: 202px;">PL NO.</td>
        </tr>
        <tr>
          <td class="tdd" style="max-width: 230px;">
            <!--<el-input v-model="form.Chdate"></el-input>-->
            <el-date-picker v-show="!Isbool" v-model="form.Chdate" value-format="yyyy-MM-dd" style="max-width: 230px;" type="date" :placeholder="$t('All.选择出货日期')">
            </el-date-picker>
            <span v-show="Isbool" class="oqctest">{{ form.Chdate }}</span>
          </td>
          <td class="tdd">
            <el-input v-show="!Isbool" v-model="form.Customer"></el-input>
            <span v-show="Isbool" class="oqctest">{{ form.Customer }}</span>
          </td>
          <td class="tdd" v-if="!form.Fileroute">
            <el-button size="mini" type="primary"  @click="yzdialogVisible = true" >{{ $t('All.参考文件') }}</el-button>
          </td>
          <td class="tdd" v-if="form.Fileroute">
            <el-tooltip effect="dark" :content="form.Reference" placement="top-start">
              <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                <span @click="fileopen()">{{ form.Reference }}</span>
                <i class="el-icon-error" v-show="!Isbool" style="padding-left: 10px; color: red;" @click="delfile()"></i>
              </div>
            </el-tooltip>
          </td>
          <td class="tdd"><span class="oqctest">{{ form.Plno }}</span></td>
        </tr>
      </table>
      <table border="1" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%; margin-top:-2px;" align="center" cellpadding="10"
        cellspacing="0">
        <tr>
          <td style="width: 230px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽箱基准')}}<br>Sampling benchmark</p>
          </td>
          <td class="tdd" colspan="1">
            <el-input v-show="!Isbool" v-model="form.Benchmark"></el-input>
            <span v-show="Isbool" class="oqctest">{{ form.Benchmark }}</span>
          </td>
        </tr>
      </table>
      <table border="1" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%; margin-top:-2px;" align="center" cellpadding="10"
        cellspacing="0">
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.品名')}}<br>Product</p>
          </td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.出货数')}}<br>Qty of shipments</p>
          </td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽样箱数')}}<br>Sampled box's Qty</p>
          </td>
          <td style="min-width: 150px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽样箱号')}}<br>Sampled box's No.</p>
          </td>
          <td style="min-width: 150px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽样重量')}}(KG)<br>Sampled weight</p>
          </td>
          <td style="min-width: 100px;">
            LOT No.
          </td>
          <td style="width: 60px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.唛头包装封箱胶纸外箱标签')}}<br>Check the collection</p>
          </td>
          <td style="width: 100px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.栈板')}}<br>Pallet</p>
          </td>
          <td style="width: 80px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.判定')}}<br>Determine</p>
          </td>
        </tr>
        <tr v-for="(item,index) in form.Productlist" :key="index">
          <td style="width: 200px;">
            <span class="oqctest">{{ item.Productname }}</span>
          </td>
          <td style="width: 100px;">
            <span class="oqctest">{{item.Quantity}}({{ item.Cases }}/{{ item.Quantitybox }})</span>
          </td>
          <td class="tdd">
            <el-input v-show="!Isbool" v-model="item.Samplingnumber"></el-input>
            <span v-show="Isbool" class="oqctest">{{ item.Samplingnumber }}</span>
            </td>
          <td class="tdd">
            <el-input v-show="!Isbool" type="textarea" :rows="3" v-model="item.Cynumber"></el-input>
            <span v-show="Isbool" class="oqctest">{{ item.Cynumber }}</span>
          </td>
          <td class="tdd">
            <el-input v-show="!Isbool" type="textarea" :rows="3" v-model="item.Weight"></el-input>
            <span v-show="Isbool" class="oqctest">{{ item.Weight }}</span>
          </td>
          <td style="width: 100px;">
            <el-input v-show="!Isbool" v-model="item.Lot"></el-input>
            <span v-show="Isbool" class="oqctest">{{ item.Lot }}</span>
          </td>
          <td style="width: 80px;">
            <el-select v-show="!Isbool" v-model="item.Concentrate" :placeholder="$t('All.请判定')">
                <el-option label="Pass" value="Pass"></el-option>
                <el-option label="Fail" value="Fail"></el-option>
            </el-select>
            <span v-show="Isbool" class="oqctest" :style="item.Concentrate == 'Fail'?'color: red;':''">{{ item.Concentrate }}</span>
          </td>
          <td style="width: 100px;">
            <el-input v-show="!Isbool" v-model="item.Pallet"></el-input>
            <span v-show="Isbool" class="oqctest">{{ item.Pallet }}</span>
          </td>
          <td style="width: 60px;">
            <el-select v-show="!Isbool" v-model="item.Judge" :placeholder="$t('All.请判定')">
                <el-option label="Pass" value="Pass"></el-option>
                <el-option label="Fail" value="Fail"></el-option>
            </el-select>
            <span v-show="Isbool" class="oqctest" :style="item.Judge == 'Fail'?'color: red;':''">{{ item.Judge }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.出货照片')}}<br>Shipment photos</p>
          </td>
          <td colspan="8" style="text-align: left;">
            <Upload v-if="form.Numbers" :Numbers="form.Numbers" :Type="1" :Isbool="Isbool"></Upload>
          </td>
        </tr>
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.备注')}}<br>Notes</p>
          </td>
          <td colspan="8" style="text-align: left;">
            <el-input v-show="!Isbool" type="textarea" :rows="3" v-model="form.Notes"></el-input>
            <span v-show="Isbool" class="oqctest">{{ form.Notes }}</span>
          </td>
        </tr>
      </table>
      <table style="width:100%;" align="center" cellpadding="10" cellspacing="0">
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.品管主管')}}<br>Notes</p>
          </td>
          <td>{{ form.Managername }}</td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.品管班长')}}<br>Quality control team leader</p>
          </td>
          <td>{{ form.Monitorname }}</td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.检验员')}}<br>Inspector</p>
          </td>
          <td>{{ form.Username }}</td>
        </tr>
      </table>
    </div>
    <el-dialog
        :title="$t('All.参考文件引用')"
        :visible.sync="yzdialogVisible"
        width="500px"
        :before-close="handleClose" append-to-body>
    {{$t('All.文件编号')}}：<el-input style="width: 300px;border: 1px solid #DCDFE6 !important;border-radius: 5px;" v-model="Mz_Numbers"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="yzdialogVisible = false">{{ $t('All.取消') }}</el-button>
      <el-button type="primary" @click="Filenumber(Mz_Numbers, form.Factory)">{{ $t('All.确认') }}</el-button>
    </span>
  </el-dialog>
  <el-dialog :title="$t('All.选择文件')" :visible.sync="filedialogVisible" width="900px" append-to-body :before-close="handleClose">
    <el-table :data="filedata" v-show="filedata.length > 0" stripe style="width: 100%" @row-click="rowdblclick">
        <el-table-column prop="Filenumber" :label="$t('All.文件编号')"></el-table-column>
        <el-table-column prop="Filename" :label="$t('All.文件名称')"></el-table-column>
        <el-table-column prop="Version" :label="$t('All.版本')"></el-table-column>
      </el-table>
      </el-dialog>
      <NewOQCqianhe v-if="qianhe" :Qianhecolse="Qianhecolse" :Qhuseroqc="Qhuseroqc" :State="form.State"/>
      <el-form style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
    <el-form-item :label="$t('All.签核记录')">
        <el-radio-group  style="padding-bottom: 20px;padding-left: 5%;" v-model="reverse">
          <el-radio :label="true">{{ $t('All.倒序') }}</el-radio>
          <el-radio :label="false">{{ $t('All.正序') }}</el-radio>
        </el-radio-group>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in Signlog"
            :key="index"
            :timestamp="activity.Date">
            {{ $PublicJs.State(activity.State)}}
            <p>{{activity.Username}}</p>
            <p>{{activity.Notes}}</p>
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Upload from './Upload.vue'
import NewOQCqianhe from './NewOQCqianhe.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    Upload,
    NewOQCqianhe
  },
  data () {
    return {
      form: {},
      isqpisboot: false,
      DetermineOptions: [{
        label: 'Pass',
        value: 'Pass'
      }, {
        label: 'Fail',
        value: 'Fail'
      }, {
        label: 'None',
        value: 'None'
      }],
      Isbool: true,
      Mz_Numbers: '',
      filedialogVisible: false,
      yzdialogVisible: false,
      filedata: {},
      qianhe: false,
      Signlog: [],
      reverse: true
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.Getuseroqc(this.$store.state.OQCExamine)
  },
  mounted () {

  },
  methods: {
    Qianhecolse () {
      this.qianhe = false
    },
    ...mapMutations(['OQCdatafun', 'TabComponentFun', 'ConductIs']),
    async Getuseroqc (row) {
      try {
        const { data: res } = await this.$http.get('/api/OQC/Getuseroqc?Numbers=' + row)
        if (res.status !== 200) {
          return this.$message.error(res.msg)
        }
        this.form = res.response
        if (this.form.Productlist.length === 0) {
          console.log('我尽量了')
          await this.ERPShipment(this.form.Plno)
        }
        this.FQCSignloglist(this.form.Numbers)
      } catch (error) {
        this.$message.error(error)
      }
    },
    async Upduseroqc (row, state) {
      row.State = state
      const postdata = {
        Newoqc: row,
        UserData: this.$store.state.Login
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/OQC/Upduseroqc', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
        this.RemoveTab(this.$t('All.新OQC检验单') + '-' + row.Numbers)
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    async Qhuseroqc (row) {
      const postdata = {
        Newoqc: this.form,
        UserData: this.$store.state.Login,
        Qianheclass: row
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/OQC/Qhuseroqc', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.qianhe = false
        this.$message.success(this.$t('All.提交成功'))
        this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
        this.RemoveTab(this.$t('All.新OQC检验单') + '-' + this.form.Numbers)
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    async Deluseroqc () {
      const delform = {
        UserData: this.$store.state.Login,
        Numbers: this.form.Numbers
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        loadingInstance.close()
        this.$confirm(this.$t('All.确认要删除'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await this.$http.post('/api/OQC/Deluseroqc', delform)
            if (res.status !== 200) {
              loadingInstance.close()
              return this.$message.error(res.msg)
            }
            loadingInstance.close()
            this.$message.success(res.msg)
            this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
            this.RemoveTab(this.$t('All.新OQC检验单') + '-' + this.form.Numbers)
          })
          .catch(() => {
            loadingInstance.close()
          })
        loadingInstance.close()
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    fileopen () {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + this.form.Fileroute
          this.ConductIs('ConductIs')
        }, 500)
      })
      // window.open(url, '_blank')
    },
    async Filenumber (item, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Filenumber?number=${item}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.ckwjisbool = false
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.filedata = res.Filedata
        this.filedialogVisible = true
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    rowdblclick (row) {
      this.filedialogVisible = false
      this.yzdialogVisible = false
      this.form.Reference = row.Filename
      this.form.Fileroute = row.Fileroute
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    delfile () {
      this.$confirm(this.$t('All.确认解除文件引用'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(async () => {
          this.form.Reference = ''
          this.form.Fileroute = ''
        })
        .catch(() => {
        })
    },
    async FQCSignloglist (row) {
      try {
        const { data: res } = await this.$http.post('/api/FQC/FQCSignloglist?Numbers=' + row)
        if (res.status !== 200) return
        this.Signlog = res.response
      } catch (error) {
        alert(error)
      }
    },
    async ERPShipment (Plno) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const OQCdataget = {
          UserData: this.$store.state.Login,
          Wmdoco: Plno.trim()
        }
        const { data: res } = await this.$http.post('/api/OQC/ERPShipment', OQCdataget)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        var newdata = res.response.Productlist.forEach(patrolItem => {
          patrolItem.Numbers = this.form.Numbers
        })
        this.form.Productlist = newdata
        /* this.$nextTick(() => { // 异步关闭
          this.TabComponentFun('NewOQCAdmin')
        }) */
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>.wbobye {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.bg-purple-light {}

.row-bg {
  background-color: #f9fafc;
}

.admin {
  border: 1px solid grey;
  border-top: 0px;
  padding-top: 15px;
  padding-left: 15px;
  text-align: left;
}

.oqctest {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.el-tag + .el-tag {
  margin-left: 10px;
  margin-top: 10px;
  }
  .button-new-tag {
    margin-top: 10px;
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    margin-top: 10px;
    width: 87px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    vertical-align: bottom;
    border: 1px solid #409eff;
    border-radius: 4px;
  }
</style>
